<app-header area="header" *ngIf="ui.navegacion" [buscador]="ui.buscador"></app-header>
<main area="main" class="d-flex flex-column overflow-hidden">
  <router-outlet></router-outlet>
</main>
<app-footer area="footer" *ngIf="ui.pie"></app-footer>


<div class="botones-accion">
  <app-subir *ngIf="scrolled"></app-subir>
  <app-whatsapp *ngIf="ui.whatsapp && esHorarioOficina"
    [phone]="5216692705424"
    message="Hola, ¿Podrían ayudarme?"></app-whatsapp>
</div>
<!--<app-image-modal></app-image-modal>-->
<app-compatible></app-compatible>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>

<!-- <div [ngClass]="((router.url != '/login' || router.url != '/portalpagos') ? 'hide':'')"> -->
<!-- <div *ngIf="router.url == '/login'">
            <app-login></app-login>
        </div>
        <div *ngIf="router.url == '/portalpagos'">
            <app-portalpagos></app-portalpagos>
        </div> -->
<!-- </div> -->
